import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { saveAs } from 'file-saver';
import {
  getUserRole, getUserProvince, getUserDistrict, hasRole,
} from '@/auth/utils';
import patientStoreModule from '../patientStoreModule';

export default function usePatientList() {
  const APARTMENT_STORE_MODULE_NAME = "patient";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, patientStoreModule);
  }

  // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const blankItem = {
    avatar: '',
    full_name: '',
    gender: null,
    phone: '',
    email: '',
    status: '',
    description: '',
    personal_doctor: null,
    pricing: [],
    medical_facilities: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const userRole = getUserRole();

  // Table Handlers
  const columns = [
    {
      label: '#',
      field: 'index',
      width: '40px',
      tdClass: 'text-center',
      thClass: 'text-center',
      sortable: false,
      orderable: false,
    },
    {
      label: 'Thao tác',
      field: 'action',
      width: '90px',
      tdClass: 'text-center',
      thClass: 'text-center',
      sortable: false,
      searchable: true,
      orderable: false,
      hidden: !hasRole('password.change'),
    },
    {
      label: 'Người dùng',
      field: 'patient',
      data: 'full_name',
      sortable: false,
      searchable: true,
      orderable: false,
    },
    {
      label: 'Địa chỉ',
      field: 'full_address',
      data: 'full_address',
      sortable: false,
      searchable: true,
      orderable: true,
    },
    {
      label: 'Số thành viên',
      field: 'totalCountMember',
      sortable: false,
      searchable: true,
      orderable: true,
    },
    // {
    //   label: 'Bác sĩ phụ trách',
    //   field: 'personal_doctor.full_name',
    //   data: 'personal_doctor.full_name',
    //   searchable: true,
    //   orderable: true,
    // },
    {
      label: 'Ngày đăng ký',
      field: 'created_at',
      width: '140px',
      sortable: false,
      // type: 'date',
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      // dateOutputFormat: 'dd-MM-yyyy HH:mm',
      data: 'created_at',
      searchable: true,
      orderable: true,
    },
    {
      label: 'Trạng thái',
      field: 'status',
      tdClass: 'text-center',
      thClass: 'text-center',
      width: '110px',
      type: 'boolean',
      data: 'status',
      sortable: false,
      searchable: true,
      orderable: true,
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const status = ref(null);
  const department = ref(null);
  const province = ref(getUserProvince());
  const district = ref(getUserDistrict());
  const ward = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    skipCount: 0,
    maxResultCount: 10,
  });

  //   API Call

  const fetchDoctors = () => {
    store
      .dispatch('patient/fetchPatients', serverParams.value)
      .then(response => {
        const { items, totalCount } = response.data;
        totalRecords.value = totalCount;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchDoctors();
  };

  const exportData = () => {
    store
      .dispatch('patient/exportPatients', serverParams.value)
      .then(response => {
        // window.location.href = response.data;
        saveAs(response.data, 'Danh sách bệnh nhân.xlsx');
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deletePatient = id => {
    store
      .dispatch('patient/deletePatient', id)
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ skipCount: (params.currentPage - 1) * serverParams.value.maxResultCount });
  };

  const onPerPageChange = params => {
    updateParams({ maxResultCount: params.currentPerPage });
  };

  const onSortChange = params => {
    const column = columns.map(_obj => _obj.field).indexOf(params[0].field);

    updateParams({
      order: [{
        dir: params[0].type,
        column,
      }],
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ filter: val });
  });
  watch(status, val => {
    updateParams({ status: val ? val.value : null });
  });
  watch(province, val => {
    updateParams({ province_id: val ? val._id : null });
  });
  watch(district, val => {
    updateParams({ district_id: val ? val._id : null });
  });
  watch(ward, val => {
    updateParams({ ward_id: val ? val._id : null });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val === 'active') return { variant: 'primary', title: "Kích hoạt", icon: 'SmileIcon' };
    if (val === 'inactive') return { variant: 'warning', title: "Chưa kích hoạt", icon: 'MehIcon' };
    return { variant: 'danger', title: "Khóa", icon: 'FrownIcon' };
  };

  const resolveFamilyDoctorVariant = val => {
    if (val) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  const resolveColForFilter = () => {
    if (userRole.name === 'department_of_health') {
      return { search: 3, other: 3 };
    }
    if (userRole.name === 'medical_facility') {
      return { search: 4, other: 4 };
    }
    return { search: 4, other: 2 };
  };

  return {
    analytics,
    item,
    columns,
    rows,
    department,
    status,
    province,
    district,
    ward,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    userRole,

    fetchData,
    deletePatient,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,

    resolveStatusVariant,
    resolveFamilyDoctorVariant,
    resolveColForFilter,
  };
}
